import React from 'react';
import '../styles/elements/preloader.scss';

class Bloobs extends React.Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="preloader">
                <div className="blobs">
                    <div className="blob-center"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                    <div className="blob"></div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"/>
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                                           result="goo"/>
                            <feBlend in="SourceGraphic" in2="goo"/>
                        </filter>
                    </defs>
                </svg>
            </div>
        );
    }
}

export default Bloobs;
